$s: 768px;
$m: 1024px;
$l: 1280px;

body.overlay-contain {
	position: relative;
}
.overlay {
	&::after {
		content: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.4;
		z-index: 80;
	}
	&.home-overlay {
		&::after {
			background-image: url(/overlays/home-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/home-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/home-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/home-1280.jpg);
			}
		}
	}
	&.projects-overlay {
		&::after {
			background-image: url(/overlays/projects-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/projects-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/projects-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/projects-1280.jpg);
			}
		}
	}
	&.project-overlay {
		&::after {
			background-image: url(/overlays/project-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/project-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/project-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/project-1280.jpg);
			}
		}
	}
	&.inspiration-overlay {
		&::after {
			background-image: url(/overlays/inspiration-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/inspiration-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/inspiration-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/inspiration-1280.jpg);
			}
		}
	}
	&.media-overlay {
		&::after {
			background-image: url(/overlays/media-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/media-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/media-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/media-1280.jpg);
			}
		}
	}
	&.biography-overlay {
		&::after {
			background-image: url(/overlays/biography-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/biography-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/biography-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/biography-1280.jpg);
			}
		}
	}
	&.contact-overlay {
		&::after {
			background-image: url(/overlays/contact-320.jpg);
			@media (min-width: $s) {
				background-image: url(/overlays/contact-768.jpg);
			}
			@media (min-width: $m) {
				background-image: url(/overlays/contact-1024.jpg);
			}
			@media (min-width: $l) {
				background-image: url(/overlays/contact-1280.jpg);
			}
		}
	}
	&.overlay-on {
		&::after {
			content: "";
		}
	}
}
